:root {
  --color-backround: #C3EDEA;
  --color-primario: #FC7E2F;
  --color-secundario: #f40552;
  --color-claro: #F8F3EB;
}

body {
  display: fixed;
  margin: 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-backround);  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, var(--color-backround), var(--color-claro));  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, var(--color-backround), var(--color-claro)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
