:root {
  --color-backround: #c3edea;
  --color-primario: #fc7e2f;
  --color-secundario: #f40552;
  --color-claro: #f8f3eb;
  --color-efecto: #fc7e2fa0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.header-info {
  width: 250px;
  height: 77px;
  word-wrap: break-word;
  color: var(--color-primario);
  border: 3px dotted var(--color-secundario);
  box-shadow: 0 5px 16px -4px var(--color-secundario);
  overflow: scroll;
}

.header-info-mobile {
  width: 220px;
  height: 107px;
  word-wrap: break-word;
  color: var(--color-primario);
  box-shadow: 0 5px 16px -4px var(--color-secundario);
  border: 3px dotted pink;
  overflow: scroll;
}

nav {
  position: relative;
  background-position: right;
  background: var(--color-backround); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--color-backround),
    var(--color-claro)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    var(--color-backround),
    var(--color-claro)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: var(--color-primario);
  font-size: calc(6px + 2vmin);
  padding: 2px 2px;
}

.container {
  bottom: 20px;
}

.App-header {
  position: relative;
  background: var(--color-primario); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--color-primario),
    var(--color-efecto)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    var(--color-primario),
    var(--color-efecto)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  min-height: 5vh;
  color: white;
}

.boton-store {
  background: transparent;
  color: var(--color-secundario);
  font-size: 10px;
  border: 2px solid var(--color-secundario);
  border-radius: 3px;
  padding: 2px 4px;
  text-transform: uppercase;
}

.boton-eleccion {
  box-shadow: 0 12px 36px -4px var(--color-backround);
  position: relative;
  width: 200px;
  height: 175px;
  background-color: var(--color-secundario);
  border: none;
  color: var(--color-backround);
  padding: 20px;
  text-align: center;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid var(--color-backround);
  border-radius: 3px;
}
.boton-eleccion:after {
  content: "";
  background: var(--color-backround);
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.boton-eleccion:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.boton-random {
  width: 100px;
  height: 100px;
  background-color: var(--color-secundario);
  color: var(--color-backround);
  padding: 2px;
  text-align: center;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid var(--color-backround);
  border-radius: 3px;
}

.boton-primario {
  position: relative;
  box-shadow: 0 12px 36px -4px var(--color-backround);
  background-color: var(--color-secundario);
  border: none;
  color: var(--color-backround);
  padding: 20px;
  text-align: center;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid var(--color-backround);
  border-radius: 3px;
}
.boton-primario:after {
  content: "";
  background: var(--color-backround);
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.boton-primario:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}
.boton-primario-2 {
  position: relative;
  background-color: var(--color-secundario);
  border: none;
  color: var(--color-backround);
  padding: 20px;
  text-align: center;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid var(--color-backround);
  border-radius: 3px;
}
.boton-primario-2:after {
  content: "";
  background: var(--color-backround);
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.boton-primario-2:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.boton-cerrar {
  position: absolute;
  right: -10%;
  top: -10%;
  background-color: var(--color-secundario);
  border: none;
  color: var(--color-backround);
  padding: 20px;
  text-align: center;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid var(--color-backround);
  border-radius: 15px;
}
.boton-cerrar:after {
  content: "";
  background: var(--color-backround);
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.boton-cerrar:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

/*background: var(--color-secundario);
  color: var(--color-backround);
  border: 2px solid var(--color-backround);
  border-radius: 3px;
  padding: 2px 4px;
  text-transform: uppercase;*/

.display-2 {
  color: var(--color-backround);
}

.lead6 {
  color: var(--color-backround);
  text-align: center;
  font-size: calc(6px + 2vmin);
}

.tituloInicial {
  color: var(--color-backround);
}

.mensajePersonalizado {
  color: var(--color-backround);
  text-align: justify;
}

.lead-6-2 {
  color: var(--color-efecto);
  text-align: right;
  font-size: calc(6px + 2vmin);
  word-wrap: break-word;
  left: 50%;
}

.lead-6-3 {
  color: var(--color-claro);
  text-align: right;
  font-size: calc(6px + 2vmin);
  word-wrap: break-word;
  left: 50%;
  margin-top: 50px;
}

.modo-select {
  background: var(--color-secundario);
  color: var(--color-backround);
  border: 2px solid var(--color-backround);
  border-radius: 1px;
  padding: 1px 3px;
  margin-top: 30px;
  width: 86%;
}

.form-group {
  padding: 1px 0px;
  color: #050821;
}

.footer-prop {
  background-color: transparent !important;
}

.sticky-header-bar {
  position: fixed;
  top: 20%;
  right: 0%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sticky-menu-bar {
  position: fixed;
  top: 40%;
  right: 1%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.into-menu-bar a {
  display: block;
  text-align: center;
  padding: 10px;
  border-radius: 25px;
  transition: all 0.3s ease;
  color: var(--color-backround);
  font-size: 10px;
  /*transform: rotate(-90deg);*/
}
.into-menu-bar a:hover {
  background-color: transparent;
  border: transparent;
  color: var(--color-primario);
}
.option1 {
  background: var(--color-secundario);
  color: var(--color-backround);
  border: 2px solid var(--color-backround);
  border-radius: 5px;
  padding: 2px 2px;
  text-transform: uppercase;
}
.option2 {
  background: var(--color-secundario);
  color: var(--color-backround);
  border: 2px solid var(--color-backround);
  border-radius: 5px;
  padding: 2px 2px;
  text-transform: uppercase;
}
.option3 {
  background: var(--color-secundario);
  color: var(--color-backround);
  border: 2px solid var(--color-backround);
  border-radius: 5px;
  padding: 2px 2px;
  text-transform: uppercase;
}

.into-terminos-bar a {
  display: block;
  text-align: center;
  padding: 5px;
  transition: all 0.3s ease;
  color: var(--color-secundario);
  font-size: 10px;
  /*transform: rotate(-90deg);*/
}
.into-terminos-bar a:hover {
  background-color: transparent;
  border: transparent;
  color: var(--color-primario);
}
.terminos1 {
  background-color: transparent !important;
  color: var(--color-secundario);
  border: 2px solid var(--color-backround);
  border-radius: 5px;
  padding: 2px 2px;
  text-transform: uppercase;
}

.scroll-terminos {
  color: black;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  display: flex;
  background-color: white;
  max-height: 50vh;
  width: auto;
  font-family: fav-fuente;
  word-wrap: break-word;
}

.card {
  box-shadow: 0 12px 36px -4px var(--color-secundario);
  position: relative;
  padding: 5px;
  margin: 35px;
  width: 60vw;
  left: 25%;
  color: var(--color-backround);
  background: var(--color-primario); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--color-primario),
    var(--color-efecto)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    var(--color-primario),
    var(--color-efecto)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.card1 {
  box-shadow: 0 12px 36px -4px var(--color-secundario);
  border: 15px solid var(--color-efecto);
  padding: 5px;
  margin: 15px;
  color: var(--color-backround);
  background: var(--color-primario); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--color-primario),
    var(--color-efecto)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    var(--color-primario),
    var(--color-efecto)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 210px;
  word-wrap: break-word;
}

.card2 {
  box-shadow: 0 12px 36px -4px var(--color-primario);
  position: relative;
  padding: 15px;
  margin: 35px;
  width: 90%;
  color: var(--color-backround);
  background: var(--color-primario); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--color-primario),
    var(--color-efecto)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    var(--color-primario),
    var(--color-efecto)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.lead-aux {
  font-family: fav-content-fuente;
  font-size: calc(6px + 1vmin);
}
.lead-aux-1 {
  font-size: calc(8px + 1vmin);
}
.lead-aux-2 {
  font-family: fav-fuente;
  margin-top: 1.5rem;
  font-size: calc(6px + 2vmin);
  color: var(--color-primario);
}

.card-header {
  background-color: transparent !important;
  color: var(--color-secundario);
  font-size: calc(6px + 1vmin);
}

.card-body {
  text-align: justify;
}

.lead-aux-mobile {
  font-family: fav-content-fuente;
  font-size: calc(6px + 2vmin);
}

.card-header-mobile {
  background-color: transparent !important;
  color: var(--color-secundario);
  font-size: calc(6px + 2vmin);
}
.photo-g {
  height: 150px;
  width: auto;
}
.photo-p {
  height: 100px;
  width: auto;
}
.logo-2-m {
  height: 200px;
  width: auto;
  margin-top: 0px;
}
.marca-g {
  height: 300px;
  width: auto;
  left: 50%;
}
.marca-p {
  position: absolute;
  height: 100px;
  width: auto;
  left: 50%;
}
.imagen-ah {
  border: 1px solid white;
  box-shadow: 0 12px 36px -4px black;
  height: 150px;
  width: 65vw;
}
.imagen-ac {
  border: 1px solid white;
  box-shadow: 0 12px 36px -4px black;
  height: 200px;
  width: auto;
  margin-top: 0px;
}
.imagen-premio {
  border: 1px solid white;
  box-shadow: 0 12px 36px -4px black;
  height: 300px;
  width: auto;
  margin-top: 0px;
}
.imagen-ag {
  border: 1px solid white;
  box-shadow: 0 12px 36px -4px black;
  height: 300px;
  width: auto;
  margin-top: 0px;
}

.imagen-av {
  position: absolute;
  border: 1px solid white;
  left: -20%;
  box-shadow: 0 12px 36px -4px black;
  height: 600px;
  width: 200px;
  margin-left: 25px;
}
.imagen-av-m {
  border: 1px solid white;
  box-shadow: 0 12px 36px -4px black;
  height: 600px;
  width: 200px;
}

.sticky-imagen {
  border: 1px solid white;
  box-shadow: 0 12px 36px -4px black;
  height: 150px;
  width: auto;
  position: fixed;
  top: 60%;
  left: 75%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sticky-switch-details {
  position: fixed;
  color: var(--color-secundario);
  top: 65%;
  left: 5%;
  z-index: 65;
}

.sticky-instructions {
  position: fixed;
  top: 88%;
  left: 7%;
  z-index: 65;
}

.sticky-boton {
  position: fixed;
  top: 30%;
  left: 87%;
  z-index: 65;
}

@media (min-width: 800px) {
  .sticky-instructions {
    position: fixed;
    top: 68%;
    left: 5%;
    z-index: 65;
  }
  .sticky-switch-details {
    position: fixed;
    color: var(--color-secundario);
    top: 45%;
    left: 3%;
    z-index: 65;
  }
  .sticky-boton {
    position: fixed;
    top: 8%;
    left: 93%;
    z-index: 65;
  }
}

.sticky-cookies {
  position: fixed;
  top: 35%;
  left: 0%;
  z-index: 65;
  max-width: 35%;
}

.sticky-mensaje {
  position: fixed;
  top: 90%;
  left: 80%;
  z-index: 5;
}

.eqi-container {
  left: -5%;
  margin-left: -5%;
  display: flex;
  justify-content: space-between;
}
.eqi-container div {
  left: 0%;
  margin-left: 1%;
  width: 200px;
  height: auto;
}

.App-link {
  color: #61dafb;
}

.parpadea {
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}
@font-face {
  font-family: fav-content-fuente;
  src: url("fuentes/ArimaKoshi-SemiBold.otf") format("opentype");
}
@font-face {
  font-family: fav-fuente;
  src: url("fuentes/LeagueSpartan-Bold.otf") format("opentype");
}

div {
  font-family: fav-fuente;
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loader {
  border: 10px solid var(--color-claro); /* Light grey */
  border-top: 10px solid var(--color-secundario); /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: 20%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
